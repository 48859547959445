<template>
	<div class="OrderDetail">
		
		<div class="Top">
			<div class="Left">
				订单详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$Jump('/my/shop/order_list')">返回订单管理列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="Status">
			<div class="Step">
				<el-steps :active="OrderStepActive">
				  <el-step title="下 单" icon="el-icon-s-goods"></el-step>
				  <el-step title="支 付" icon="el-icon-s-finance"></el-step>
				  <el-step title="发 货" icon="el-icon-s-promotion"></el-step>
				  <el-step title="收 货" icon="el-icon-s-claim"></el-step>
				   <el-step title="完 成" icon="el-icon-s-help"></el-step>
				</el-steps>
			</div>
		</div>
		
		<div class="Detail">
			<div class="List Title">
				<span class="C0"></span>
				<span class="C1">商品</span>
				<span class="C2">属性</span>
				<span class="C3">单价</span>
				<span class="C4">数量</span>
				<span class="C5">小计</span>
				<span class="C6">操作</span>
			</div>
			
			<div class="List" v-for="(child,childI) in Order.Children" :key="childI">
				<span class="C0">
					<img :src="child.GoodsThumb" />
				</span>
				<span class="C1">[{{Order.Shop.Name}}] {{child.GoodsName}}</span>
				<span class="C2">{{child.SkuName}}</span>
				<span class="C3">{{child.Price}}</span>
				<span class="C4">{{child.Num}}</span>
				<span class="C5">{{child.TotalFee}}</span>
				<span class="C6">
					<el-button v-if="child.Status == 90" size="mini" @click="$Jump('/my/seller/appeal/'+child.AppealId)">查看/处理申诉</el-button>
					<i v-else>-</i>
				</span>
			</div>
			
			<div class="Count">
				<div>
					<li><span>商品总额：</span><em>¥{{Order.TotalFee}}</em></li>
					<li><span>运费：</span><em>¥0.00</em></li>
					<li><span>订单总额：</span><em>¥{{Order.TotalFee}}</em></li>
					<li><span>当前状态：</span><em>{{Order.StatusName}}</em></li>
					<li v-if="Order.Status == 60" style="margin-top: 10px;"><el-button size="mini" type="danger" @click="ExpressDialog = true">去发货</el-button></li>
				</div>
			</div>
			
		</div>
		
		<div class="Express">
			
			<div class="Address">
				<h3>收货人信息</h3>
				<li><span>收 货 人：</span>{{Order.Address.Addressee}}</li>
				<li><span>收货地址：</span>{{Order.Address.Province}}{{Order.Address.City}}{{Order.Address.Region}}{{Order.Address.Town ? Order.Address.Town:''}}{{Order.Address.Detail}}</li>
				<li><span>手机号码：</span>{{Order.Address.Mobile}}</li>
				
				<h3 class="Invoice">发票信息</h3>
				<template v-if="Order.Invoice">
					<template v-if="Order.Invoice.Type == 'special'">
						<li><span>发票类型：</span>增值税专票</li>
						<li><span>发票抬头：</span>{{Order.Invoice.Title}}</li>
						<li><span>税号：</span>Order.Invoice.DutyParagraph</li>
						<li><span>单位开户银行：</span>Order.Invoice.CompanyBank</li>
						<li><span>单位银行账号：</span>Order.Invoice.CompanyBankCardId</li>
						<li><span>单位联系地址：</span>Order.Invoice.CompanyAddress</li>
					</template>
					<template v-if="Order.Invoice.Type == 'normal'">
						<li><span>发票类型：</span>增值税普票</li>
						<li><span>发票抬头：</span>{{Order.Invoice.Title}}</li>
						<li><span>税号：</span>Order.Invoice.DutyParagraph</li>
						<li v-if="Order.Invoice.Mail"><span>电子发票邮箱：</span>{{Order.Invoice.Mail}}</li>
					</template>
					<template v-if="Order.Invoice.Type == 'person'">
						<li><span>发票类型：</span>增值税普票</li>
						<li><span>发票抬头：</span>{{Order.Invoice.Title}}</li>
						<li v-if="Order.Invoice.Mail"><span>电子发票邮箱：</span>{{Order.Invoice.Mail}}</li>
					</template>
				</template>
				<template v-else>
					<li>未设置发票，可联系客服补开</li>
				</template>
				
			</div>
			
			<div class="Dynamic">
				<h3>
					快递动态
					<span v-if="Order.Status == 60"><em @click="ExpressDialogOpen()" style="cursor: pointer;">点此发货</em></span>
					<span v-if="Order.Status >= 70">{{Order.Express.ExpressCompanyName}}<em>(单号:{{Order.Express.ExpressCode}})</em></span>
				</h3>
				
				<div class="NoExpress" v-if="Order.Status == 60">
					<i class="el-icon-truck"></i>当前未发货，<em @click="ExpressDialogOpen()">点此发货</em>
				</div>
				
				<div  class="NoExpress" v-if="Order.Status >= 70">
				<vuescroll :ops="vuescroll_ops" ref="vs">
				  <div>
					  <el-steps :space="'40px'" direction="vertical" :active="1">
					    <el-step :title="d.ftime + ' ' + d.context" :key="dI" v-for="(d,dI) in Order.Express.Dynamic"></el-step>
					  </el-steps>
				  </div>
				  </vuescroll>
				</div>
				
			</div>
			
		</div>
		
		
		<el-dialog
		  title="快递/物流发货单号录入"
		  :visible="ExpressDialog"
		  width="600px"
		  :before-close="ExpressDialogClose">
		  
		  <div class="ExpressDialogForm">
			  <li>
				  <span>
					  快递/物流公司
				  </span>
				  
				  <em style="width: 200px;">
					  <el-select
					      v-model="ExpressCompanyCode"
						  size="medium"
					      filterable
					      remote
					      reserve-keyword
					      placeholder="请选择快递公司"
					      :remote-method="ExpressCompanyRemote"
					      :loading="ExpressCompanyRemoteLoading">
					      <el-option
					        v-for="(item,itemI) in ExpressCompanyOpts"
					        :key="itemI"
					        :label="item.Name"
					        :value="item.Code">
					      </el-option>
					    </el-select>
				  </em>
				  
				  <label style="color: rgba(0,0,0,0.4);">输入关键词可检索国内外快递公司</label>
					
			  </li>
			  
			  
			  <li>
				  <span>
					  发货单号
				  </span>
				  <em>
					<el-input size="medium" v-model="ExpressCode"></el-input>
				  </em>
			  					
			  </li>
			  
		  </div>
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="ExpressDialog = false">取 消</el-button>
		    <el-button type="primary" @click="SetExpress()">确 定</el-button>
		  </span>
		</el-dialog>
		
	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination,Steps,Step,Dialog} from 'element-ui'
	import vuescroll from 'vuescroll'
	export default {
	  name: 'ShopOrdersDetail',
	  props: {
	  },
	  data() {
	      return {
			  Order:null,
			  OrderStepActive:1,
			  ExpressCode:'',//发货单号
			  ExpressDialog:false,
			  ExpressCompanyCode:'', //发货快递/物流公司
			  ExpressCompanyRemoteLoading:false,
			  ExpressCompanyOpts:[],
			  RecommendExpressCompanyOpts:[],//推荐快递公司只需加载一次
			  vuescroll_ops: {
			  	vuescroll: {},
			  	scrollPanel: {},
			  	rail: {
			  	},
			  	bar: {
			  		onlyShowBarOnScroll:false,
			  		background: '#000000',
			  		opacity: 0.2,
			  	}	
			  },
	      }
	  },   
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination,
		'el-steps':Steps,
		'el-step':Step,
		'el-dialog':Dialog,
		'vuescroll':vuescroll
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('进入订单的路径不对，请退出')
			return
		}
		this.Order = {
			Id:this.$route.params.Id
		}
		this.GetDetail()
	  },
	  methods:{
		  GetDetail(){
		  
		  		let data = {
		  			Service:'Order',
		  			Class: 'Order',
		  			Action: 'Information',
		  			Id:this.Order.Id,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			
					this.Order = res.Data
					
					if(this.Order.Status >= 50 && this.Order.Status < 60){
						this.OrderStepActive = 1
					}else if(this.Order.Status >= 60 && this.Order.Status < 70){
						this.OrderStepActive = 2
					}else if(this.Order.Status >= 70 && this.Order.Status < 80){
						this.OrderStepActive = 3
					}else if(this.Order.Status == 80){
						this.OrderStepActive = 4
					}else if(this.Order.Status > 80){
						this.OrderStepActive = 5
					}
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  ExpressDialogClose(){
			  this.ExpressDialog = false
		  },
		  ExpressDialogOpen(){ //初始化快递公司信息，默认载入前X个知名公司
				this.ExpressDialog = true
				if(this.RecommendExpressCompanyOpts.length == 0){
					this.GetRecommendExpressCompanyOpts()
				}
		  },
		  GetRecommendExpressCompanyOpts(){
			  let data = {
			  	Service:'Order',
			  	Class: 'ExpressCompany',
			  	Action: 'List',
			  	Page:1,
			  	PageSize:50,
			  	IsRecommend:1
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
			  		for(var i=0;i<res.Data.ExpressCompanyList.length;i++){
			  				
			  			this.ExpressCompanyOpts.push({
			  				Code:res.Data.ExpressCompanyList[i].Code,
			  				Name:res.Data.ExpressCompanyList[i].Name,
			  			})
			  		}
			  		this.RecommendExpressCompanyOpts = JSON.parse(JSON.stringify(this.ExpressCompanyOpts))
			  	
			  })
			  .catch(function (response) {
			  		this.$message('网络请求快递信息错误')
			  })
		  },
		  ExpressCompanyRemote(_keyWords){
			  //去除首尾空格
			  _keyWords = this.$Trim(_keyWords)
			  if(_keyWords.length == 0){
				  this.ExpressDialogOpen()
				  return
			  }
			  this.ExpressCompanyRemoteLoading = true
			  let data = {
			  	Service:'Order',
			  	Class: 'ExpressCompany',
			  	Action: 'List',
			  	Page:1,
			  	PageSize:20,
			  	Name:_keyWords
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	this.ExpressCompanyRemoteLoading = false
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
				
				this.ExpressCompanyOpts = []
			  	for(var i=0;i<res.Data.ExpressCompanyList.length;i++){
		
					this.ExpressCompanyOpts.push({
						Code:res.Data.ExpressCompanyList[i].Code,
						Name:res.Data.ExpressCompanyList[i].Name,
					})
				}
				
			  	
			  })
			  .catch(function (response) {
					this.ExpressCompanyRemoteLoading = false
					this.ExpressDialogOpen()
					this.$message('网络请求错误')
			  })
		  },
		  SetExpress(){
			  let data = {
				Service:'Order',
				Class: 'Order',
				Action: 'Update',
				Id:this.Order.Id,
				ExpressCompanyCode:this.ExpressCompanyCode,
				ExpressCode:this.$Trim(this.ExpressCode),
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
				
				if(res.ErrorId != 0){
					this.$message(res.Msg)
					return
				}

				this.ExpressDialog = false
				this.GetDetail()
				
			  })
			  .catch(function (response) {
					this.$message('网络请求快递信息错误')
			  })
		  },
	  }
	}
</script>

<style scoped>
	.OrderDetail{
		margin: 20px;
		padding: 20px;
	}
	
	.OrderDetail .Top{
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		padding-bottom: 20px;
	}
	.OrderDetail .Top .Left{
		
	}
	.OrderDetail .Top .Right{
		flex: 1;
		text-align: right;
		color: #999999;
	}
	
	.Status{
		width: 100%;
		height: 100px;
		background-color: #ffffff;
		border-bottom: 1px solid rgba(0,0,0,0.06);
		display: flex;
		justify-content: center;
		margin-top: 20px;
		padding-top: 40px;
	}
	.Step{
		width: 80%;
	}
	
	.Detail{
		background-color: #FFFFFF;
		margin-top: 20px;
		padding: 30px 20px;
		
	}
	.Detail .List{
		display: flex;
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid rgba(0,0,0,0.05);
		align-items: center;
	}
	.Detail .Title{
		color: rgba(0,0,0,0.3);
	}
	.List .C0,.List .C1,.List .C2,.List .C3,.List .C4,.List .C5,.List .C6{
		width: 120px;
	}
	.List .C0 img{
		height: 80px;
	}
	.List .C1{
		flex: 1;
	}
	.List .C2{
		width: 140px;
		margin-left: 10px;
		margin-right: 10px;
	}
	
	.Count{
		text-align: right;
	}
	
	.Express{
		background-color: #FFFFFF;
		margin-top: 20px;
		padding: 30px 20px;
		display: flex;
	}
	.Express .Address{
		width: 400px;
		border-right: 1px solid rgba(0,0,0,0.1);
		padding-right: 20px;
		margin-right: 20px;
	}
	.Express .Address h3{
		color: #999999;
	}
	.Express .Address li{
		margin-bottom: 5px;
		display: flex;
		word-wrap: break-word;
	}
	.Express .Address li span{
		display: block;
		width: 100px;
	}
	
	.Express .Invoice{
		margin-top: 30px;
	}
	
	
	.Express h3 span{
		float: right;
		font-weight: normal;
		font-size: 14px;
	}
	
	.Express .Dynamic{
		flex: 1;
		flex-direction: column;
		display: flex;
	}
	.Express .Dynamic h3{
		color: rgba(0,0,0,0.4);
	}
	.Express .Dynamic h3 span{
		color: rgba(0,0,0,0.5);
	}
	.Dynamic .NoExpress{
		background-color: rgba(0,0,0,0.05);
		padding: 20px;
		flex: 1;
		max-height: 200px;
	}
	.Dynamic .NoExpress em{
		color: rgba(40,151,255,1);
		text-decoration: underline;
		cursor: pointer;
	}
	.ExpressDialogForm{
		
	}
	.ExpressDialogForm li{
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}
	.ExpressDialogForm li span{
		width: 120px;
	}
	.ExpressDialogForm li em{
		display: block;
		width: 300px;
	}
	.ExpressDialogForm li label{
		color: rgba(228,0,0,1);
		margin-left: 5px;
		font-size: 0.9rem;
	}
</style>
